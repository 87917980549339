/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import { Router } from '@app';
import { Button } from '@client/components';

// App
import Page from '@/client/pages/_layout/_page';
import useHeader from '@client/pages/useHeader';

/*----------------------------------
- CONTROLEUR
----------------------------------*/

/* 
    NOTE: Don"t use layout since we need to be sure the user is logged
*/
Router.error( 404, { layout: false }, ({ data }) => {
    return (
        <div class='row al-center pd-3' style={{ minHeight: '70vh' }}>
            <div class="card w-3-4 col pd-2">

                <div class="col al-center">

                    <i src="times-circle" class="fg error xxl" />

                    <h1>Page Not Found</h1>

                    <p>{data.message}</p>
                </div>

                <Button type="primary" link="/">Go to home</Button>
            </div>
        </div>
    )
});